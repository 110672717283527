<template>
  <div class="leaderboard_page">
    <div class="top_holder"></div>
    <!-- banner -->
    <div class="banner">插件排行榜-TOP50</div>
    <!-- 面包屑 -->
    <div class="breadcrumb web-center" style="margin: 40px auto 20px">
      <a-breadcrumb>
        <a-breadcrumb-item
          ><a href="#/extension">插件中心</a></a-breadcrumb-item
        >
        <a-breadcrumb-item><a href="javascript:;">排行榜</a></a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- 排行榜 -->
    <div class="leaderboard_body web-center">
      <a-tabs @change="handleChange">
        <a-tab-pane
          v-for="(item, index) in leaderboardKind"
          :key="index"
          :tab="item"
          class="tab_page"
        >
          <div class="tab_page_body">
            <div
              class="list_item"
              v-for="(item, index) in listData"
              :key="item.name"
              @click="$router.push(`/extension/detail?id=${item.id}`)"
              :class="[
                index == 0 ? 'rank1' : '',
                index == 1 ? 'rank2' : '',
                index == 2 ? 'rank3' : '',
              ]"
            >
              <div class="rank_num">
                <span class="txt">{{ index + 1 }}</span>
              </div>
              <div class="item_body">
                <img :src="item.icon_url" alt="" class="icon" />
                <div class="info">
                  <p class="title">{{ item.title }}</p>
                  <p class="desc">{{ item.subtitle }}</p>
                </div>
              </div>

              <div class="add_btn" @click.stop="addExtension(item)">
                {{ item.is_install ? "分配环境" : "立即添加" }}
              </div>
            </div>
          </div>
          <div class="tab_page_footer">
            <div class="footer_btn" v-show="!isSpand" @click="spand">TOP50</div>
            <div class="footer_btn" v-show="isSpand" @click="shrink">收起</div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 添加插件弹窗 -->
    <AddExtension
      :visible="visible"
      :extensionItem="item"
      @close="visible = false"
      @install="install"
    ></AddExtension>
    <!-- <AddExtension
      :visible="visible"
      :extensionId="extensionId"
      :isInstall="isInstall"
      :downloadUrl="downloadUrl"
      :guid="guid"
      @close="visible = false"
      @install="install"
    ></AddExtension> -->
  </div>
</template>

<script>
import {
  leaderboardList,
  weekLeaderboardList,
  monthLeaderboardList,
} from "@/api/extension.js";
import AddExtension from "../components/AddExtension.vue";
export default {
  components: {
    AddExtension,
  },
  data() {
    return {
      visible: false,
      item: {},
      currentCategoryIndex: 0,
      page: 1,
      pagesize: 20,
      total: 50,
      isSpand: false,
      leaderboardKind: ["周榜", "月榜", "总榜"],
      listData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      switch (this.currentCategoryIndex) {
        case 0:
          weekLeaderboardList({
            page: this.page,
            pagesize: this.pagesize,
          }).then((res) => {
            if (res.data.code == 200) {
              this.listData = res.data.data;
            }
          });
          break;
        case 1:
          monthLeaderboardList({
            page: this.page,
            pagesize: this.pagesize,
          }).then((res) => {
            if (res.data.code == 200) {
              this.listData = res.data.data;
            }
          });
          break;
        case 2:
          leaderboardList({ page: this.page, pagesize: this.pagesize }).then(
            (res) => {
              if (res.data.code == 200) {
                this.listData = res.data.data.list;
              }
            }
          );
          break;
      }
    },
    addExtension(item) {
      this.item = item;
      this.visible = true;
    },
    install() {
      this.item.is_install = true;
    },
    handleChange(value) {
      this.currentCategoryIndex = value;
      this.getList();
    },
    spand() {
      this.pagesize = 50;
      this.isSpand = true;
      this.getList();
    },
    shrink() {
      this.pagesize = 20;
      this.isSpand = false;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.leaderboard_page {
  .top_holder {
    height: 60px;
    background-color: #fff;
  }
  .banner {
    height: 100px;
    background: url("~@/assets/img/extension/leaderboard_banner.png")
      center/cover;
    font-size: 32px;
    font-weight: 800;
    color: #ffffff;
    line-height: 100px;
    text-align: center;
  }
  .leaderboard_body {
    background-color: #fff;
    ::v-deep .ant-tabs-nav {
      line-height: 72px;
      .ant-tabs-tab {
        font-size: 20px;
      }
    }
    ::v-deep .ant-tabs {
      .ant-tabs-nav-scroll {
        .flex(center);
      }

      .tab_page {
        padding: 36px 40px 21px;
        min-height: 1000px;
        .flex(@direction:column);

        .tab_page_body {
          width: 100%;
          .list_item {
            margin-bottom: 33px;
            .flex();
            .rank_num {
              width: 64px;
              height: 64px;
              font-weight: 800;
              color: #374567;
              line-height: 64px;
              text-align: center;
            }
            .item_body {
              width: 900px;
              cursor: pointer;
              .flex(flex-start);
              .icon {
                width: 60px;
                height: 60px;
                margin-right: 20px;
              }
              .info {
                .title {
                  width: 820px;
                  margin-bottom: 12px;
                  font-size: 16px;
                  font-weight: 600;
                  color: #374567;
                  .ellipsis();
                  &:hover {
                    color: @blue;
                  }
                }
                .desc {
                  width: 820px;
                  font-size: 14px;
                  color: #7882a1;
                  .ellipsis();
                  &:hover {
                    color: @blue;
                  }
                }
              }
            }
            .add_btn {
              width: 76px;
              height: 32px;
              background: #4c84ff;
              box-shadow: 0px 2px 5px 0px rgba(76, 132, 255, 0.31);
              border-radius: 2px;
              color: #fff;
              line-height: 32px;
              text-align: center;
              cursor: pointer;
              &:hover {
                background-color: #6f9cff;
              }
            }
            // 单独修改前三个样式
            &.rank1 {
              .rank_num {
                background: url("~@/assets/img/extension/leaderboard_rank1.png")
                  center/cover;
              }
              .txt {
                display: none;
              }
            }
            &.rank2 {
              .rank_num {
                background: url("~@/assets/img/extension/leaderboard_rank2.png")
                  center/cover;
              }
              .txt {
                display: none;
              }
            }
            &.rank3 {
              .rank_num {
                background: url("~@/assets/img/extension/leaderboard_rank3.png")
                  center/cover;
              }
              .txt {
                display: none;
              }
            }
          }
        }
        .tab_page_footer {
          .footer_btn {
            width: 120px;
            height: 38px;
            margin: auto;
            background: #ecf2ff;
            border-radius: 22px;
            line-height: 38px;
            text-align: center;
            color: @blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>